import React from "react";
import { graphql } from "gatsby";
import tw from "twin.macro";
import { Link } from "gatsby";

import PageWrapper from "../components/PageWrapper";
import ContentWrapper from "../components/ContentWrapper";
import Layout from "../components/Layout";

import Content from "../components/Content";
import SideWrapper from "../components/SideWrapper";
import SponsorSlider from "../components/SponsorSlider";
import Sidenavs from "../components/Sidenavs";

const Item = tw(Link)``;

const Sitemap = ({ data, location }) => {
  const {
    page,
    allWpPage: { pages },
    allWpPost: { posts },
  } = data;

  const pageList = (pages) =>
    Array.from(pages).map((page, i) => (
      <li key={i}>
        <Item to={page.uri}>{page.title}</Item>
        {page.wpChildren?.nodes?.length > 0 && (
          <ul>{pageList(page.wpChildren.nodes)}</ul>
        )}
        {page.title.toLowerCase() === "blog" && (
          <ul>
            {posts.map(({ title, uri }, i) => (
              <li key={i}>
                <Item to={uri}>{title}</Item>
              </li>
            ))}
          </ul>
        )}
      </li>
    ));

  return (
    <Layout location={location} inner>
      <PageWrapper>
        <ContentWrapper>
          <Content>
            <h1>Sitemap</h1>
            <ul>{pageList(pages, posts)}</ul>
          </Content>
        </ContentWrapper>
        <SideWrapper>
          <Sidenavs />
          <SponsorSlider sidebar />
        </SideWrapper>
      </PageWrapper>
    </Layout>
  );
};

export default Sitemap;

export const sitemapQuery = graphql`
  query {
    allWpPage(
      filter: { parentId: { eq: null } }
      sort: { order: ASC, fields: title }
    ) {
      pages: nodes {
        ...Page
        ...ChildPages
        ...GrandChildPages
      }
    }
    allWpPost {
      posts: nodes {
        ...Post
      }
    }
  }
  fragment Page on WpPage {
    title
    uri
  }
  fragment Post on WpPost {
    title
    uri
  }
  fragment ChildPages on WpPage {
    wpChildren {
      nodes {
        ... on WpPage {
          ...Page
        }
      }
    }
  }
  fragment GrandChildPages on WpPage {
    wpChildren {
      nodes {
        ...ChildPages
      }
    }
  }
`;
